<template>
    <div class="special">
        <div class="special-title">
            <span class="barsLightSp">
                <span class="sp">
                    <van-icon name="bars" class="barsLight" @click="barsClick" />
                </span>
            </span>
            <span class="txt-con">
                <span class="txt">真题挑战</span>
            </span>
        </div>

        <div class="zttz">
            <span class="txt">
                <span class="on">第 {{num+1}} 题</span>
                <span> /共 {{topicData.length}} 题</span>
            </span>
            <span class="check">
                <span class="huan mr_12" v-if="num" @click="prev">上一题</span>
                <span class="huan on mr_12" v-else>上一题</span>
                <span class="huan" @click="next" v-if="topicData.length-1 > num">下一题</span>
                <span class="huan on" v-else>下一题</span>
            </span>
        </div>

        <div class="trainTopic ">
            <div class="title">挑战前建议先完成以下专题的刷题训练：</div>
            <ul>
                <li v-for="(item,n) in currentInfo.outline" :key="n">
                    <span>{{item}}</span>
                    <!-- <van-rate readonly v-model="star_this" :size="15" color="#F9C200" /> -->
                </li>
                <!-- <li>
                    <span>四十四、公式法求解</span>
                    <van-rate readonly v-model="star_this" :size="15" color="#F9C200" />
                </li>
                <li>
                    <span>四十四、公式法求解</span>
                    <van-rate readonly v-model="star_this" :size="15" color="#F9C200" />
                </li> -->
            </ul>
        </div>

        <div class="shuati">

            <div>
                <!-- 做题 -->
                <div class="special-panel mb_16">
                    <div class="topicRefCon">
                        <!-- <topic ref="topicRef" :topicData="topicData" /> -->
                        <div class="topic-con">
                            <div  id="topicMain"></div>
                        </div>
                    </div>
                </div>

                <van-collapse v-model="activeNamesJx">
                    <van-collapse-item title="真题解析" name="1">
                        <div class="ztjx" id="ztjx"></div>
                    </van-collapse-item>
                </van-collapse>

            </div>
        </div>
        
        <navLeft :zhentiNum="num" ref="nav" @getActive="getActive" :navList="navList" active="active" />
        
        <van-icon @click="backTop" class="backTop" name="back-top" />

    </div>
</template>

<script>
    import navLeft from './component/leftNav';
    import topic from './component/topicZhenti';
    import Step from './component/Step';
    import utils from '@/assets/js/utils';
    var $ = require( "jquery" );
    export default {
        name:'special',
        data() {
            return {
                star_this:0,//当前星星
                activeNames:[],
                activeNamesJx:[], // 真题解析
                showNum: 0,//左侧菜单展开那个
                leftInfo:{
                    showNum:0, //当前展开 
                    checkedNum:0, // 当前选中
                    data:'',
                    level:1, //难度
                },
                currentInfo:{}, // 当前页面的左侧菜单展示信息

                topicData:{}, // 获取的题的内容
                specialName:'',//专题名称
                titleName: '', //标题名称

                zhentiNum: 0,

                num:0, // 真题 当前题
            }
        },
        components: {
            navLeft,
            topic,
            Step
        },
        created () {
            this.$store.commit('getNavList');
        },
        mounted () {
            this.init();
        },
        watch: {
            activeNamesJx(newValue, oldValue) {
                this.$nextTick(()=>{
                    let data = this.topicData[this.num];
                    let dom_1 = document.getElementById('ztjx');
                    dom_1 && this.$kx.render(data['解析'], dom_1, {
                        displayMode: true,
                    });
                })
            },
            navList(val){
                if(val && val.length>0){
                    this.$refs.nav.init(val);
                }
            },
            num(val) {
                let data = this.topicData[val];

                let dom = document.getElementById('topicMain');
                dom && this.$kx.render(data['真题'], dom, {
                    displayMode: true,
                });


                let dom_1 = document.getElementById('ztjx');
                dom_1 && this.$kx.render(data['解析'], dom_1, {
                    displayMode: true,
                });
            }
        },
        computed: {
            navList() {
                return this.$store.state.navList;
            },
            navListZhenti() {
                return this.$store.state.navListZhenti;
            }
        },
        methods: {
            backTop(){
                document.documentElement.scrollTop=0;
                document.body.scrollTop=0;
                $('.barsLight').addClass('on')
            },
            init() {
                // 获取菜单
                let query = this.$route.query;
                this.active = +query.active || 0;
                if(this.$store.state.navList.length>0){
                    this.$refs.nav.init(this.$store.state.navList);
                }
            },
            // 左侧菜单展现
            barsClick(){
                this.$refs.nav.showNav = true;
                this.$refs.nav.getStar();
            },
            // 获取菜单选中信息
            getActive(leftInfo){
                this.num = 0;
                this.leftInfo = leftInfo;
                let currentInfo = this.$store.state.navListZhenti[0].childs[0];
                
                this.$refs.nav.showNav = false;
            
                leftInfo.data && (currentInfo = leftInfo.data);
                console.log('currentInfo',currentInfo)
            
                this.currentInfo = currentInfo;
                this.showTopic();
            },
            // 展示题
            showTopic(){
                let {dir}=this.currentInfo;
                console.log('dir', dir);
                let url=dir;
                this.$ajax({url,
                    success:(res=>{
                        this.topicData = res;
                        let data = res[this.num];

                        let dom = document.getElementById('topicMain');
                        dom && this.$kx.render(data['真题'], dom, {
                            displayMode: true,
                        });


                        let dom_1 = document.getElementById('ztjx');
                        dom_1 && this.$kx.render(data['解析'], dom_1, {
                            displayMode: true,
                        });
                        
                    })
                })
            },

            next(){
                this.num++;
            },

            prev(){
                this.num--
            },
            ltlx(){
                this.activeNames = [];
            }
        },
    }
</script>

<style lang="less" scoped>
    .special{
        padding: 12px;
        background: #F7F8FB;
        text-align: left;
        height: 100vh;
        &-title{
            font-size: 22px;
            text-align: left;
            padding-top: 12px;
            display: flex;
            .barsLightSp{
                position: fixed;
                left: 0px;
                top: 250px;
                z-index: 9;
                // background: #fff;
                line-height: 60px;
                display: inline-block;
                overflow: hidden;
                width: 30px;
                height: 60px;
                .sp{
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    background: #fff;
                    display: inline-block;
                    position: absolute;
                    left: -100%;
                }
            }
            .van-icon-bars{
                position: absolute;
                left: 32px;
                color: #415FD5;
                position: relative;
                top: 4px;
                &.on{
                    animation:mymove 1s infinite;
                    @keyframes mymove
                    {
                        from {color: #15C392;}
                        to {color: #415FD5;}
                    }
                }
            }
            .txt-con{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .txt{
                margin-bottom: 16px;
                font-size: 22px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #2A3155;
                line-height: 29px;
            }
        }
        .topic-con{
            overflow: auto;
        }
        .zttz{
            display: flex;
            justify-content: space-between;
            line-height: 53px;
            height: 53px;
            padding: 0 12px;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(42, 49, 85, 0.1);
            border-radius: 2px;
            .txt{
                color: #9498AA;
                .on{
                    font-size: 14px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #2A3155;
                }
            }
            .check{
                .huan{
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #2A3155;
                    height: 32px;
                    line-height: 19px;
                    padding: 7px 12px ;
                    background: #EFF2F7;
                    border-radius: 2px;
                    &.on{
                        color: #BFC1CC;
                        background: #EFF2F7;
                    }
                }

            }
        }
        .trainTopic{
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(42, 49, 85, 0.1);
            border-radius: 2px;
            min-height: 50px;
            margin-top: 16px;
            padding: 14px 12px;
            .title{
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #606580;
                padding-bottom: 6px;
            }
            ul{
                li{
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 8px;
                }
            }
        }
        &-panel{
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(42, 49, 85, 0.1);
            border-radius: 2px;
            margin-top: 16px;
            box-sizing: border-box;
            padding: 16px 12px;
            .star-con{
                padding-bottom: 16px;
                text-align: center;
            }
            .difficulty{
                height: 48px;
                background: #F4F6FD;
                border-radius: 2px;
                line-height: 48px;
                text-align: center;
                color: #9498AA;
                .radio-sp{
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #FFFFFF;
                    border: 2px solid #9498AA;
                    border-radius: 6px;
                    margin-right: 5px;
                    margin-left: 10px;
                }
                .levelOn{
                    color: #415FD5;
                    .radio-sp{
                        border: 2px solid #415FD5
                    }
                }
                .levelSucc{
                    color: #15C392;
                    .radio-sp{
                        border: 2px solid #15C392
                    }
                }
            }
        }
        .analysis{
            overflow: auto;
        }
        &_foot{
            height: 72px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(42, 49, 85, 0.1);
            border-radius: 2px;
            box-sizing: border-box;
            padding: 12px;
            margin-bottom: 50px;
            // .next{
            //     position: relative;
            //     z-index: 9;
            // }
            
        }
        .backTop{
            position: fixed;
            bottom: 20px;
            right: 10px;
            font-size: 24px;
            z-index: 9;
        }
    }
</style>