<template>
    <div class="topic">
        
        <div class="topic-box" :class="topicIsTrue===true?'succ':topicIsTrue===false?'err':''">
            <div class="topic-con">
                <div  id="topicMain"></div>
            </div>
        </div>
        
    </div>
</template>

<script>
    var $ = require( "jquery" );
    export default {
        data() {
            return {
                checkNum:'', // 单选选项
                checkMoreArr: [], // 多选选中数组
                submitStatu: false, // 填空只允许提交一次
                active:'', // 选项颜色 err 选错了, succ 选对了, no 该选，但是未选 , 选中 on

                startTime:0,//倒计时开始时间
                endTime:0,//倒计时结束时间
                time: '',// 耗时
                star_this:0,
            }
        },
        props: {
            topicData: {
                type: Object,
                default: {}
            }
        },
        mounted () {
            this.showTopic();
        },
        computed: {
            topicIsTrue() {
                return this.$store.state.topicIsTrue;
            },
            getNavInfo() {
                return this.$store.state.getNavInfo;
            },
            stepShow() {
                return this.$store.state.stepShow;
            }
        },
        watch: {
            stepShow(newValue, oldValue) {
                console.log('newValue',newValue)
                this.submitStatu = newValue;
            }
        },
        methods: {
            changeNext(){
                this.$parent.barsClick();
            },
            // 真题 展示
            showTopicZhenti() {
                this.startTime = +new Date();
                let topicData = this.topicData;
                
                let dom = document.getElementById('topicMain');
                dom && this.$kx.render(topicData['真题'], dom, {
                    displayMode: true,
                });
                
            },
            // 倒计时结束 清算
            timeEnd(){
                let endTime = +new Date();
                let startTime = this.startTime;
                let allTime = endTime - startTime;
                let s = (allTime/1000)/60;// 耗时几分钟
                let m = (allTime/1000)%60;
                let time = '';//s+'分'+m+'秒'
                if(s>=1){
                    time = parseInt(s) + '分';
                }
                if(m>=1){
                    time += parseInt(m) + '秒';
                }
                this.time = time;
            },
            // 下一题
            next(){
                this.$parent.next();
            },
            getStar(){
                this.$api.navigate({},res=>{
                    let list = res.list;
                    let getNavInfo = this.$store.state.getNavInfo;
                    list.map((e,n)=>{
                        e.childs.map((el,i)=>{
                            if(getNavInfo && getNavInfo.showNum==n && getNavInfo.checkNum==i){
                                this.$parent.star_this = el.stars_count;
                                this.star_this = el.stars_count;
                            }
                        })
                    });
                },err=>{
                    console.error(err);
                });
            },
            // 下一专题
            async nextzhuanti(){
                let getNavInfo = this.$store.state.getNavInfo;
                let navList = this.$store.state.navList;
                if(navList.length==getNavInfo.showNum+1){
                    return
                }
                navList.map((e,n)=>{
                    if(e.childs.length==getNavInfo.checkNum+1 && getNavInfo.showNum==n){
                        // getNavInfo.showNum++;
                        getNavInfo.data = navList[getNavInfo.showNum+1].childs[0];
                    }else{
                        e.childs.map((el,i)=>{
                            if(getNavInfo.showNum==n && (getNavInfo.checkNum+1) ==i){
                                getNavInfo.data = el;
                            }
                        })
                    }
                });
                console.log('getNavInfo',getNavInfo);
                
                let param = {
                    dangqianzhuanti: getNavInfo.data.name,
                    dangqianwancheng: ''
                }
                this.$api.user_info_write({param,method:'post'},res=>{
                    this.$store.commit('getNavList');
                });
            }
        },
    }
</script>

<style lang="less" scoped>
    .topic{
        &-box{
            margin: 12px 0;
            background: #F4F6FD;
            border-radius: 2px;
            padding: 32px 0;
            &.succ{
                background: #EAFAF5;
            }
            &.err{
                background: #FFECEA;
            }

            .option{
                padding-top: 12px;
                ul{
                    // display: flex;
                    // flex-wrap:wrap;
                    li{
                        background: #FFFFFF;
                        overflow: auto;
                        border-radius: 2px;
                        border: 1px solid #E6E8EF;
                        text-align: left;
                        padding: 8px 12px;
                        margin: 6px 16px;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        .option_span{
                            display: block;
                            /deep/.katex-display{
                                margin: 0;
                            }
                        }
                        .el-icon-success{
                            display: none;
                        }
                        &.on{
                            color: #fff;
                            background: #415FD5;
                            border-radius: 2px;
                        }
                        &.no{
                            color: #15C392;
                            border-radius: 2px;
                        }
                        &.err{
                            color: #fff;
                            background: #FF4236;
                            border: 1px solid #FF4236;
                        }
                        &.succ{
                            color: #fff;
                            background: #15C392;
                            border: 1px solid #15C392;
                        }
                        :hover{

                        }
                    }
                    &.duoxuan{
                        li{
                            .el-icon-success{
                                display: none;
                            }
                            &.on{
                                color: #fff;
                                background: #6F9AE1;
                                border: 1px solid #6F9AE1;
                            }
                            &.active{
                                color: #fff;
                                background: #4BD794;
                                border: 1px solid #4BD794;
                            }
                            &.err{
                                color: #fff;
                                background: #F35656;
                                border: 1px solid #F35656;
                            }
                            &.succ{
                                .el-icon-success{
                                    display: inline-block;
                                    float: right;
                                    margin-top: 12px;
                                    margin-right: 12px;
                                    color: #4BD794;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-con{
            overflow: auto;
        }
        #topicMain{
            text-align: center;
        }
        .changeNext{
            display: flex;
            justify-content: space-between;
            .van-button{
                width: 45%;
            }
        }
        .error-txt{
            width: 182px;
            margin: 0 auto;
            text-align: center;
            height: 38px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #606580;
            line-height: 19px;
            padding-top: 4px;
            padding-bottom: 16px;
        }
        .succ-txt{
            padding-top: 4px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            line-height: 19px;
            text-align: center;
            padding-bottom: 16px;
            .p1{
                color: #2A3155;
                padding-bottom: 4px;
            }
            .p2{
                color: #9498AA;
                &.on{
                    color: #15C392;
                }
                &.err-on{
                    color: #FF4236;
                }
            }
        }
    }
</style>